.error404 {
	color: var(--black);
	padding: 50px 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	font-size: 30px;

	&-title {
		margin: 0;
		font-weight: 700;
		margin-bottom: 20px;
	}

	&-text {
		font-size: 18px;
	}
}

@media (--media-desktop) {
	.error404 {
		&-title {
			font-size: 40px;
		}

		&-text {
			font-size: 25px;
			width: 40%;
		}
	}
}
