@keyframes rotation {
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}

	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}

.loader {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: inline-block;
	border-top: 3px solid var(--red);
	border-right: 3px solid transparent;
	animation: rotation 1s linear infinite;
	position: absolute;
}
