.modal {
	position: fixed;
	z-index: 10;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: var(--black80);

	&-content {
		background-color: var(--white);
		margin: 15% auto;
		padding: 20px;
		width: 90%;
		gap: 30px;
		display: flex;
		flex-direction: column;
		border-radius: 5px;
		position: relative;
	}

	&-logo {
		border: 2px solid white;
		position: absolute;
		height: 70px;
		background-color: var(--blueFFU);
		width: 70px;
		top: -30px;
		padding: 10px;
		border-radius: 50%;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--pinkFFU);
		}
	}

	&-title {
		font-family: var(--futura);
		text-transform: uppercase;
		position: relative;
		padding-bottom: 10px;
		font-size: 20px;
		border-bottom: 1px solid var(--blue10);
		margin-bottom: 20px;
	}

	&[aria-hidden='false'] {
		visibility: visible;
		pointer-events: auto;
		animation: modalFadeIn 0.3s ease-out;
		animation-fill-mode: forwards;
	}

	&[aria-hidden='true'] {
		animation: modalFadeOut 0.3s ease-out;
		animation-fill-mode: forwards;
		pointer-events: none;
	}

	&-close {
		width: 40px;
		height: 40px;
		cursor: pointer;
		align-self: flex-end;
		border-radius: 50%;
		padding: 5px;
		transition: background-color 0.3s;
		border: none;
		background-color: var(--transparent);

		svg {
			width: 100%;
			height: 100%;
			fill: var(--black);
		}
	}

	@keyframes modalFadeIn {
		from {
			opacity: 0;
		}

		to {
			opacity: 1;
		}
	}

	@keyframes modalFadeOut {
		from {
			opacity: 1;
		}

		to {
			opacity: 0;
		}
	}
}

@media (--media-tablet) {
	.modal {
		&-content {
			width: 70%;
			max-width: 600px;
		}
	}
}

@media (--media-desktop) {
	.modal {
		&-content {
			width: 50%;
		}

		&-close {
			transition: background-color 0.3s;

			&:hover {
				background-color: var(--gray);
			}
		}
	}
}
