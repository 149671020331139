.socials {
	display: flex;

	svg {
		width: 25px;
		height: 25px;
		fill: var(--black);
	}

	a {
		color: #adcccc;
		display: flex;
	}

	&-listItem {
		display: flex;
		width: 25px;
		height: 25px;

		&:not(:last-child) {
			margin-right: 10px;
		}
	}

	&-link {
		border-radius: 3px;
		overflow: hidden;
	}
}

@media (hover: hover) {
	.socials {
		&-listItem {
			transition: transform 0.3s;

			&:hover {
				transform: translateY(-3px);
			}
		}
	}
}
