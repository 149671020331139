:root {
	--containerMaxWidth: 1100px;
	--smallcontainerMaxWidth: 600px;

	/* fonts */
	--futura: futurapt-bold, sans-serif;
	--poppins: poppins, sans-serif;

	/* colors */
	--white: #fff;
	--white50: rgb(255 255 255 / 50%);
	--white80: rgb(255 255 255 / 80%);
	--lightWhite: #f9f9f9;
	--black: #414042;
	--black80: rgb(0 0 0 / 80%);
	--trueBlack: #000;
	--red: #e64e4e;
	--green: #00c12c;
	--blue: #1863dc;
	--blueSecondary: #1a4995;
	--blueTertiary: #007bff;
	--greenSecondary: #10a37f;
	--gray: #f2f2f2;
	--gray40: rgb(2 2 2 /40%);
	--gray60: rgb(2 2 2 /60%);
	--darkGray: #808080;
	--orange: #ffdead;
	--transparent: rgb(0 0 0 / 0%);
	--success: #155724;
	--successBackgroundColor: #d4edda;
	--error: #ff5c64;
	--errorBackgroundColor: rgb(255 92 100 / 10%);

	/* blue */
	--blueFFU: #190f41;
	--blue50: #423c5a;
	--blue40: #716d83;
	--blue30: #a19dad;
	--blue20: #d0ced6;
	--blue10: #ecebef;

	/* pink */
	--pinkFFU: #e5c1b6;
	--pink50: #eacdc5;
	--pink40: #eed7d1;
	--pink30: #f2e1dc;
	--pink20: #f7ebe8;
	--pink10: #fbf5f3;

	/* shadow */
	--boxShadow: rgb(0 0 0 / 12%) 0px 2px 20px 0px;

	/* focus */
	--focusBorderColor: #80bdff;
	--boxShadowFocusColor: 0 0 0 0.2rem rgb(0 123 255 / 25%);

	/* Transitions */
	--cubicBezier: cubic-bezier(0.83, 0, 0.17, 1);
}

/* Navbar breakpoint */
@custom-media --media-navbar only screen and (max-width: 1024px);

/* Breakpoints */
@custom-media --media-mobile only screen and (max-width: 749px);
@custom-media --media-tablet only screen and (min-width: 750px);
@custom-media --media-desktop only screen and (min-width: 1024px);
@custom-media --media-desktop-large only screen and (min-width: 1200px);
