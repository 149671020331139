.footer {
	font-family: var(--futura);
	background-image: linear-gradient(0deg, #1d1d1d, rgb(34 34 34 / 90%));
	color: var(--lightWhite);
	padding: 30px;

	&-socials {
		svg {
			fill: var(--lightWhite);
		}
	}

	&-logoIcon {
		width: 40px;
		height: 40px;
		overflow: hidden;
		border-radius: 50%;
	}

	&-logo {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 50px;
		column-gap: 20px;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--pinkFFU);
			background-color: var(--blueFFU);
			padding: 6px;
		}
	}

	&-logoText {
		text-transform: uppercase;
		font-size: 17px;
	}

	&-socials,
	&-copyright {
		display: flex;
		justify-content: center;
	}

	&-list {
		display: flex;
		flex-direction: column;
		row-gap: 10px;
	}

	&-listItem {
		border-bottom: 1px solid rgb(255 255 255 / 7%);
		padding: 5px 0;
		font-size: 13px;
		font-weight: 600;

		&:last-child {
			border-bottom: none;
		}
	}

	&-contact,
	&-pages,
	&-legals {
		margin-bottom: 50px;
	}

	&-listItemTitle {
		text-transform: uppercase;
		position: relative;
		margin-bottom: 10px;
		font-size: 17px;
	}

	&-pages {
		.footer-list {
			gap: 10px;
		}

		.footer-listItemTitle {
			margin-bottom: 20px;
		}
	}

	&-listItemLink {
		-webkit-appearance: none;
		background-color: var(--transparent);
		border-radius: 0;
		color: var(--blue30);
		font-family: var(--poppins);

		&.cookies {
			border: 0;
			padding: 0;
			font-family: var(--poppins);
			font-weight: inherit;
			font-size: inherit;
			cursor: pointer;
		}
	}

	&-provider {
		font-size: 13px;
		display: flex;
		justify-content: center;
		font-family: var(--poppins);
		color: var(--blue30);
	}

	&-providerLink {
		text-decoration: underline;
		color: inherit;
	}

	&-copyright {
		color: var(--blue30);
		font-size: 13px;
		text-align: center;
		font-family: var(--poppins);
	}

	&-bottom {
		padding-top: 30px;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	&-image {
		margin-bottom: 50px;
	}

	&-subscribe {
		border: 1px solid rgb(255 255 255 / 7%);
		margin-bottom: 50px;
		align-items: center;
		display: flex;
		padding: 20px 10px;
		gap: 10px;

		.cta.main {
			background-image: none;
			background-color: var(--black);
			color: var(--white);
			font-size: 12px;
			font-family: inherit;
		}
	}
}

@media (--media-desktop) {
	.footer {
		&-container {
			display: grid;
			grid-template-areas:
				'a a a'
				'b c e'
				'b d e';
			grid-template-columns: 1fr 1fr 1fr;
			max-width: var(--containerMaxWidth);
			margin: 0 auto;
		}

		&-socials {
			margin-bottom: 30px;
			border-top: none;
			padding-top: 0;
		}

		&-contact,
		&-pages,
		&-legals {
			margin-bottom: 0;
		}

		&-logo {
			grid-area: a;
		}

		&-contact {
			grid-area: c;
		}

		&-pages {
			grid-area: b;
		}

		&-legals {
			grid-area: d;
		}

		&-bottom {
			border: 0;
			padding-top: 0;
			grid-area: e;
			gap: 5px;
		}

		&-listItemTitle,
		&-logoText {
			font-size: 19px;
		}

		&-listItem {
			width: 70%;
		}
	}
}

@media (hover: hover) {
	.footer {
		&-listItemLink {
			&:hover {
				color: var(--lightWhite);
			}
		}
	}
}
