.cta {
	color: var(--black);

	&:not(.simpleLink) {
		font-weight: 700;
		font-size: 12px;
		text-transform: uppercase;
		text-align: center;
		position: relative;
		background-color: transparent;
		border: 1px solid var(--black);
		outline: none;
		padding: 8px 20px;
		display: inline-flex;
		align-items: center;
	}

	&.main {
		&:not(.noStyle) {
			transition:
				background-color 0.3s ease,
				color 0.3s ease;

			&::before {
				content: '';
				position: absolute;
				width: 6px;
				height: 6px;
				border-top: 2px solid var(--black);
				border-right: 2px solid var(--black);
				transform: translateY(-50%) rotate(45deg);
				top: 50%;
				right: 15px;
				transition:
					color 0.3s,
					opacity 0.3s;
				opacity: 0;
			}

			&.black {
				background-color: var(--black);
				color: var(--white);
			}

			&.green {
				border-radius: 3px;
				padding: 0 40px;
				background-color: var(--greenSecondary);
				color: var(--white);
				border: none;

				&::before {
					border-top: 2px solid var(--white);
					border-right: 2px solid var(--white);
				}

				.loader {
					border-top: 3px solid var(--white);
				}
			}
		}
	}

	&.disabled {
		cursor: not-allowed;
	}

	&-content {
		visibility: visible;
		opacity: 1;
		transition:
			visibility 0.3s,
			opacity 0.3s,
			transform 0.3s ease;
	}

	&-loader {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		place-items: center;
		visibility: hidden;
		opacity: 0;
		transition:
			visibility 0.3s,
			opacity 0.3s;
	}

	&.loading {
		pointer-events: none;

		.cta-loader {
			display: grid;
			opacity: 1;
			visibility: visible;
		}

		.cta-content {
			visibility: hidden;
			opacity: 0;
		}
	}
}

@media (--media-desktop) {
	.cta {
		&:not(.simpleLink) {
			font-size: 15px;
		}

		&.main:not(.noStyle) {
			&:hover {
				cursor: pointer;
				background-color: var(--black);
				color: var(--white);

				.cta-content {
					transform: translateX(-10px);
				}

				&::before {
					opacity: 1;
					border-top-color: var(--white);
					border-right-color: var(--white);
				}
			}

			&.green {
				&:hover {
					background-color: var(--greenSecondary);

					&::before {
						opacity: 1;
						border-top-color: var(--white);
						border-right-color: var(--white);
					}
				}
			}
		}
	}
}
