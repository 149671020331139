@font-face {
	font-family: FuturaPT-Bold;
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src:
		local(''),
		url('../fonts/FuturaPT-Bold.woff2') format('woff2');
}

/* eb-garamond-regular - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src:
		local(''),
		url('../fonts/eb-garamond-v26-latin-regular.woff2') format('woff2'); /* Super Modern Browsers */
}

/* eb-garamond-500 - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 500;
	font-display: swap;
	src:
		local(''),
		url('../fonts/eb-garamond-v26-latin-500.woff2') format('woff2');
}

/* eb-garamond-600 - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src:
		local(''),
		url('../fonts/eb-garamond-v26-latin-600.woff2') format('woff2');
}

/* eb-garamond-700 - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src:
		local(''),
		url('../fonts/eb-garamond-v26-latin-700.woff2') format('woff2');
}

/* eb-garamond-800 - latin */
@font-face {
	font-family: 'EB Garamond';
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src:
		local(''),
		url('../fonts/eb-garamond-v26-latin-800.woff2') format('woff2');
}

/* poppins-100 - latin */
@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 100;
	font-display: swap;
	src:
		local('poppins'),
		url('../fonts/poppins-v19-latin-100.woff2') format('woff2');
}

/* poppins-200 - latin */
@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src:
		local('poppins'),
		url('../fonts/poppins-v19-latin-200.woff2') format('woff2');
}

/* poppins-300 - latin */
@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src:
		local('poppins'),
		url('../fonts/poppins-v19-latin-300.woff2') format('woff2');
}

/* poppins-regular - latin */
@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src:
		local('poppins'),
		url('../fonts/poppins-v19-latin-regular.woff2') format('woff2');
}

/* poppins-500 - latin */
@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 500;
	src:
		local('poppins'),
		url('../fonts/poppins-v19-latin-500.woff2') format('woff2');
}

/* poppins-600 - latin */
@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src:
		local('poppins'),
		url('../fonts/poppins-v19-latin-600.woff2') format('woff2');
}

/* poppins-700 - latin */
@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src:
		local('poppins'),
		url('../fonts/poppins-v19-latin-700.woff2') format('woff2');
}

/* poppins-800 - latin */
@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 800;
	font-display: swap;
	src:
		local('poppins'),
		url('../fonts/poppins-v19-latin-800.woff2') format('woff2');
}

/* poppins-900 - latin */
@font-face {
	font-family: Poppins;
	font-style: normal;
	font-weight: 900;
	font-display: swap;
	src:
		local('poppins'),
		url('../fonts/poppins-v19-latin-900.woff2') format('woff2');
}
