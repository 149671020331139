:root {
	--lineColor: var(--black);
}

@media (--media-navbar) {
	.hamburger {
		position: fixed;
		top: 0;
		right: 0;
		width: 70px;
		height: 70px;
		background-color: transparent;
		user-select: none;
		border: none;
		cursor: pointer;
		margin-left: auto;
		z-index: 10;

		.hamburgerLines {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			margin: 0 auto;
			transform: translateY(-1px);
			display: inline-block;
			cursor: pointer;
			user-select: none;
			transition: all 300ms ease 0s;
			width: 27px;
			height: 3px;
			background-color: var(--black);

			&::before {
				content: '';
				top: 8px;
				display: inline-block;
				width: 27px;
				height: 3px;
				background-color: var(--black);
				position: absolute;
				left: 0;
				transform-origin: 50% 50%;
				transition:
					top 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 300ms,
					transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s,
					background-color 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
			}

			&::after {
				content: '';
				top: -8px;
				display: inline-block;
				width: 27px;
				height: 3px;
				background-color: var(--black);
				position: absolute;
				left: 0;
				transform-origin: 50% 50%;
				transition:
					top 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 300ms,
					transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s,
					background-color 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
			}
		}

		&.active {
			.hamburgerLines {
				background-color: transparent;

				&::before {
					transition:
						top 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s,
						transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 300ms;
					top: 0;
					transform: rotate3d(0, 0, 1, 45deg) scale(1.1);
					background-color: var(--white);
				}

				&::after {
					transition:
						top 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 0s,
						transform 300ms cubic-bezier(0.165, 0.84, 0.44, 1) 300ms;
					top: 0;
					transform: rotate3d(0, 0, 1, -45deg) scale(1.1);
					background-color: var(--white);
				}
			}
		}
	}
}

@media (--media-desktop) {
	.hamburger {
		display: none;
	}
}
