.topBar {
	position: sticky;
	top: 0;
	max-width: 100%;
	font-size: 13px;
	transition:
		transform 0.3s,
		border-bottom 0.3s;
	background-color: var(--white);
	box-shadow: 0 15px 10px -15px rgb(120 120 120 / 15%);
	height: 70px;
	z-index: 10;

	&-wrapper {
		height: 100%;
		display: none;
	}

	&-quote {
		display: none;
	}

	svg {
		fill: var(--blue50);
	}

	&-socials {
		display: none;
	}

	&-infos {
		display: flex;
		align-items: center;
		padding-left: 15px;
	}

	&-link {
		.cta-content {
			font-family: var(--futura);
			display: flex;
			align-items: center;
			gap: 5px;
		}

		svg {
			border-radius: 3px;
			height: 25px;
			width: 25px;
			padding: 3px;
		}
	}

	&-newsletter.cta.main {
		color: var(--black);
		border: none;
		padding: 0;
		text-transform: capitalize;
		font-size: 13px;
		font-family: var(--futura);
		cursor: pointer;
	}
}

@media (--media-desktop) {
	.topBar {
		position: relative;
		border-bottom: 1px solid var(--blue10);
		height: 40px;
		box-shadow: none;
		padding: 0 10px;

		&-wrapper {
			max-width: var(--containerMaxWidth);
			margin: 0 auto;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&-quote {
			display: block;
			font-size: 12px;
			font-weight: 700;
			text-transform: uppercase;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}

		&-socials {
			display: flex;
			align-items: center;
			gap: 5px;
		}

		&-infos {
			display: flex;
			flex-direction: row;
			padding-left: 0;
		}
	}
}
