* {
	box-sizing: border-box;
	margin-left: 0;
	margin-right: 0;
	-webkit-overflow-scrolling: touch;

	&::before,
	&::after {
		box-sizing: border-box;
	}
}

body {
	position: relative;
	font-family: var(--poppins);
	font-weight: 300;
	font-size: 16px;
	min-height: 100vh;
	color: var(--black);
	line-height: 1.5;

	&.overflow-y {
		overflow-y: hidden;
	}
}

html {
	&.orejimeHtml-WithModalOpen {
		body {
			position: relative;
			overflow: visible;
		}
	}
}

h1,
h2 {
	margin: 0;
}

img {
	max-width: 100%;
}

a {
	text-decoration: none;
}

.grecaptcha-badge {
	visibility: hidden;
}

@media (--media-desktop) {
	input,
	textarea,
	button,
	select,
	label,
	a {
		&:focus-visible {
			border-color: var(--focusBorderColor);
			outline: 0;
			box-shadow: var(--boxShadowFocusColor);
		}
	}
}
