.header {
	position: fixed;
	width: 100%;
	height: 70px;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	padding-left: 10px;
	background-color: var(--white);
	transition:
		background-color 0.5s,
		box-shadow 0.5s,
		max-width 0.3s,
		transform 0.3s;

	&-wrapper {
		display: flex;
		align-items: center;
		height: 100%;
		width: var(--containerMaxWidth);
		max-width: 100%;
		margin: 0 auto;
	}

	&-wrapperLogoLink {
		background-color: var(--blueFFU);
		border-radius: 50%;
		display: block;
		height: 50px;
		min-width: 50px;
		overflow: hidden;
		padding: 9px;
		position: relative;
		width: 50px;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--pinkFFU);
		}
	}

	&-wrapperOverlay {
		position: fixed;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		background-color: rgb(0 0 0 / 70%);
		transition:
			opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1),
			visibility 0.3s;
		opacity: 0;
		visibility: hidden;
		transition-delay: 0.5s;

		&.active {
			opacity: 1;
			visibility: visible;
			transition-delay: 0.1s;
		}
	}

	&-wrapperTextInfos {
		display: block;
		margin-left: 10px;

		span {
			display: block;
			white-space: nowrap;
			font-size: 10px;
			color: var(--black);

			&:first-child {
				font-size: 16px;
				color: var(--black);
				font-family: var(--futura);
				text-transform: uppercase;
				margin-bottom: 5px;
			}

			&:last-child {
				font-style: italic;
			}
		}
	}

	&.scrolling {
		box-shadow: 0 2px 40px rgb(15 15 15 / 5%);

		.header-wrapperTextInfos {
			span {
				opacity: 1;
				visibility: visible;
				transform: translateX(0);

				&:last-child {
					transition-delay: 0.1s;
				}
			}
		}
	}
}

@media (--media-tablet) {
	.header {
		padding: 0 10px;
	}
}

@media (--media-desktop) {
	.header {
		position: sticky;
		top: 0;
		height: 80px;

		&-wrapperLogoLink {
			display: block;
			min-width: 70px;
			width: 70px;
			height: 70px;
			position: relative;
			background-color: var(--blueFFU);
			padding: 14px;
			overflow: hidden;
		}

		&-wrapperOverlay {
			display: none;
		}

		&-wrapperTextInfos {
			display: block;
			margin-left: 10px;

			span {
				display: block;
				white-space: nowrap;
				font-size: 10px;
				color: var(--black);
				opacity: 0;
				transition:
					opacity 0.3s,
					transform 0.7s,
					visibility 0.3s;
				transform: translateX(-10%);

				&:last-child {
					transition-delay: 0.1s;
				}
			}
		}

		&.scrolling {
			box-shadow: 0 2px 40px rgb(15 15 15 / 5%);

			.header-wrapperTextInfos {
				span {
					opacity: 1;
					visibility: visible;
					transform: translateX(0);

					&:last-child {
						transition-delay: 0.1s;
					}
				}
			}
		}
	}
}
