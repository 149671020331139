.navbar {
	.submenu-btn {
		font-family: var(--futura);
	}
}

@media (--media-navbar) {
	.navbar {
		position: fixed;
		width: 75%;
		height: 100%;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		transform: translateX(-100%);
		background-color: var(--white);
		transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);
		z-index: 2;
		padding: 50px 10px 10px;
		display: flex;
		flex-direction: column;
		overflow-y: scroll;

		&.active {
			transform: translateX(0%);
		}

		&-list {
			display: flex;
			flex-direction: column;
			margin-bottom: 50px;
		}

		&-listItemLink,
		.submenu-listItemLink {
			display: inline-block;
			padding: 10px;
			color: var(--black);
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 700;
			position: relative;

			&::before {
				display: inline-flex;
				position: absolute;
				content: '';
				width: 0;
				top: 50%;
				left: -4px;
				transform: translateY(-50%);
				height: 0;
				border-style: solid;
				border-width: 6px 0 6px 6px;
				border-color: var(--transparent) var(--transparent) var(--transparent)
					var(--transparent);
			}
		}

		&-listItem,
		.submenu-listItem {
			font-family: var(--futura);

			&.active {
				.navbar-listItemLink,
				.submenu-listItem.active .submenu-listItemLink {
					&::before {
						border-color: var(--transparent) var(--transparent) var(--transparent)
							var(--darkGray);
					}
				}
			}
		}

		&-listItem:not(.dropdown),
		.submenu-listItem {
			&.active {
				pointer-events: none;
			}
		}

		.submenu-list {
			position: relative;
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.3s;
			padding: 0 15px;
			margin-left: 10px;
			border-left: 1px solid var(--blue20);
		}

		.button {
			position: absolute;
			width: 15px;
			height: 15px;
			right: -23px;
			top: 50%;
			transform: translateY(-50%) scale(0.7);
			pointer-events: none;

			&::before,
			&::after {
				content: '';
				position: absolute;
				background-color: var(--black);
				transition: transform 0.25s ease-out;
			}

			/* Vertical line */
			&::before {
				top: 0;
				left: 50%;
				width: 2px;
				height: 100%;
				margin-left: -1px;
			}

			/* horizontal line */
			&::after {
				top: 50%;
				left: 0;
				width: 100%;
				height: 2px;
				margin-top: -1px;
			}
		}

		.submenu-btn {
			background-color: transparent;
			border: none;
			padding: 15px 10px;
			text-transform: uppercase;
			font-size: 14px;
			color: var(--black);
			width: 100%;
			text-align: left;

			&.opened {
				.submenu-btnText::after {
					transform: rotate(225deg);
				}

				.button {
					cursor: pointer;

					&::before {
						transform: rotate(90deg);
					}

					&::after {
						transform: rotate(180deg);
					}
				}
			}
		}

		.submenu-btnText {
			position: relative;
			display: inline-block;
			pointer-events: none;
			font-weight: 700;
		}

		.submenu-btnTextItem {
			padding: 15px 0;
		}

		.socials {
			margin-top: auto;
			margin-bottom: 10px;

			svg {
				width: 30px;
				height: 30px;
			}
		}

		.socials-listItem {
			width: 30px;
			height: 30px;
		}

		.copyright {
			font-size: 12px;
		}
	}
}

@media (--media-desktop) {
	.navbar {
		max-width: var(--containerMaxWidth);
		margin: 0 auto;
		width: 100%;

		&-list {
			display: flex;
			justify-content: flex-end;
			align-items: baseline;
			gap: 15px;
			padding-top: 5px;
		}

		&-listItemLink {
			display: block;
			text-transform: uppercase;
			font-size: 12px;
			font-family: var(--futura);
			color: var(--black);
			font-weight: 700;
			padding-bottom: 5px;
			white-space: nowrap;
			position: relative;
			border-bottom: 2px solid var(--transparent);

			&::after {
				content: '';
				position: absolute;
				width: 100%;
				transform: scaleX(0);
				height: 2px;
				bottom: -2px;
				left: 0;
				background-color: var(--black);
				transform-origin: bottom right;
				transition: transform 0.25s ease-out;
			}
		}

		&-listItemLink:hover {
			&::after {
				transform: scaleX(1);
				transform-origin: bottom left;
			}
		}

		&-listItem {
			transition:
				color 0.3s,
				background-color 0.3s;
			position: relative;
			z-index: 1;

			&.active {
				.navbar-listItemLink {
					border-bottom: 2px solid var(--black);
				}
			}

			&.active:not(&.dropdown),
			.submenu-listItem.active {
				pointer-events: none;
			}
		}

		.submenu-list {
			display: flex;
			flex-direction: column;
			position: absolute;
			opacity: 0;
			visibility: hidden;
			transition:
				opacity 0.3s,
				visibility 0.3s,
				transform 0.3s;
			box-shadow: 0 8px 16px rgb(0 0 0 / 15%);
			background-color: var(--white);
			padding: 10px;
			transform: translateY(10px);
			pointer-events: none;
			gap: 10px;

			&:focus-within {
				opacity: 1;
				visibility: visible;
			}
		}

		&-listItem.dropdown {
			&:hover {
				.submenu-list {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
					pointer-events: auto;
				}
			}
		}

		.submenu-btn {
			padding: 0 0 15px;
			background-color: transparent;
			border: none;
			text-align: left;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 700;

			&:focus-visible,
			&:focus,
			&:focus-within {
				~ .submenu-list {
					opacity: 1;
					visibility: visible;
				}
			}
		}

		.submenu-listItemLink {
			display: inline-block;
			white-space: nowrap;
			text-transform: uppercase;
			font-size: 12px;
			color: var(--black);
			font-family: var(--futura);
			font-weight: 700;
			position: relative;
			padding: 0 0 5px 10px;
			margin-right: 10px;

			> span {
				display: inline-flex;
				transition: transform 0.3s;
			}

			&::after {
				content: '';
				position: absolute;
				width: 2px;
				height: 70%;
				background-color: var(--transparent);
				transition: background-color 0.3s;
				left: 0;
			}

			&:hover {
				> span {
					transform: translateX(3px);
				}

				&::after {
					background-color: var(--black);
				}
			}
		}

		.submenu-listItem {
			&.active {
				.submenu-listItemLink::after {
					background-color: var(--black);
				}
			}
		}

		.submenu-btnTextItem {
			display: flex;
			align-items: center;
			color: var(--black);
			transition: color 0.3s;
		}

		.submenu-btnTextItemCaret {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 2px;
			vertical-align: middle;
			border-top: 4px solid;
			border-right: 4px solid transparent;
			border-left: 4px solid transparent;
		}

		.submenu-btnText {
			display: flex;
		}

		.button,
		.socials,
		.copyright {
			display: none;
		}
	}
}
